import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirect
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  const form = useRef();
  const navigate = useNavigate(); // Initialize the navigate hook

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_9g7tude', 'template_hiym0to', form.current, {
        publicKey: '1w8p1yD-aYBKDFfXE',
      })
      .then(
        () => {
          // Show success toast
          toast.success('Your message has been sent successfully!', {
            position: 'top-center', // Use position as a string
            autoClose: 3000, // Auto close after 3 seconds
          });

          // Delay redirect to allow the toast to be visible
          setTimeout(() => {
            navigate('/thanks'); // Redirect to the /thanks page after 3 seconds
          }, 3000);
        },
        (error) => {
          // Show failure toast
          toast.error('Failed to send the message. Please try again.', {
            position: 'top-center', // Use position as a string
            autoClose: 3000, // Auto close after 3 seconds
          });
        }
      );
  };

    return (
        <>
         
        <section className='pageTitle'>
          <div className='pageTitleImg'><img src='assets/images/slider/2.jpg' width="100%" /></div>
          <div className='pageTitle-bgColor'></div>
          <div className='pageTitle-content'>
          <div className="container">
            <div>
              <h1>Contact</h1>
            </div>
          </div>
          </div>
        </section>

        
        <main>
        <ToastContainer />
            <section>
                <div className="container py-5">
                    <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                            <div><h2>Get in Touch</h2></div>
                            <div className='contact-list'>
                            <div className='card p-3 shadow-sm cont-item d-md-flex'>
                                <div className='cont-img'><img src='assets/svg/location.svg' width="50px" /></div>
                                <div className='cont-content'><p>NH. 76, Airport Road,
Near Geetanjali College Dabok,
Udaipur (Raj.) 313022</p></div>
                            </div>
                            <div className='card p-3 shadow-sm cont-item d-md-flex'>
                                <div className='cont-img'><img src='assets/svg/email2.svg' width="50px" /></div>
                                <div className='cont-content'><a href='mailto:info@theurbandream.com'>info@theurbandream.com</a></div>
                            </div>
                            <div className='card p-3 shadow-sm cont-item d-md-flex'>
                                <div className='cont-img'><img src='assets/svg/call2.svg' width="50px" /></div>
                                <div className='cont-content'>
                                <a href='tel:02942946374'> +0294-2946374</a><br/>
                            <a href='tel:919265697465'> +91-9265697465</a><br/>
                            <a href='tel:919828024453'> +91-9828024453</a><br/>
                            <a href='tel:9166115918'> +91-9166115918</a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                        <div><h2>Get in Touch</h2></div>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className='row'>
                                <div class="col-sm-12 col-md-6 mb-3">
                                    {/* <label for="" class="form-label">Name</label> */}
                                    <input type="text" class="form-control" name="name" placeholder="Name"/>
                                </div>
                                <div class="col-sm-12 col-md-6 mb-3">
                                    {/* <label for="" class="form-label">Phone</label> */}
                                    <input type="text" class="form-control" name="phone" placeholder="Phone"/>
                                </div>
                                <div class="col-sm-12 col-md-12 mb-3">
                                    {/* <label for="" class="form-label">Email</label> */}
                                    <input type="text" class="form-control" name="email" placeholder="Email"/>
                                </div>
                                <div className="col-sm-12 col-md-12 mb-3">
                                    {/* <label For="exampleFormControlTextarea1" className="form-label">Message</label> */}
                                    <textarea className="form-control" id="exampleFormControlTextarea1" name='message' placeholder="Message" rows="3"></textarea>
                                </div>
                                <div class="col-sm-12 mb-3">
                                <button type='submit' class="btn btn-dark">Submit</button>
                                </div>
                            </div>
                        </form>
                        </div>
                        
                    </div>
                </div>
            </section>
        </main>   
        </>
    );
}

export default Contact;
