import React from 'react';

const Thanks = () => {
    return (
        <>
         <section className='pageTitle'>
          <div className='pageTitleImg'><img src='assets/images/slider/2.jpg' width="100%" /></div>
          <div className='pageTitle-bgColor'></div>
          <div className='pageTitle-content'>
          <div className="container">
            <div>
              <h1>Email</h1>
            </div>
          </div>
          </div>
        </section>

        
        <main>
        <section>
        <div className="container py-5">
          <div className="text-center pt-5 pb-5">
            <h2>Thank You...</h2>
            <p>Your Inquiry details has been submitted Successfully."<br/>
              We will get back to you as soon as possible"</p>
          </div>
        </div>
      </section>
     
        </main>   
        </>
    );
}

export default Thanks;
