import React from 'react';
import { NavLink } from 'react-router-dom';

const About = () => {
    return (
        <>
        <section className='pageTitle'>
          <div className='pageTitleImg'><img src='assets/images/slider/2.jpg' width="100%" /></div>
          <div className='pageTitle-bgColor'></div>
          <div className='pageTitle-content'>
          <div className="container">
            <div>
              <h1>About</h1>
            </div>
          </div>
          </div>
        </section>

        
        <main>
        <section className='position-relative'>
  <div className='position-absolute top-0 right-0 w-40'><img src='assets/images/back/Interior-scheme.png' className='w-100' /></div>
  <div className="container py-4 position-relative">
              <div className='row'>
                <div className='col-md-5'>
                  <div className='imgAbout'>
                  <img src="assets/images/home/about1.jpg" width="100%" class="up-down radius-img shadow img1 imgCover" alt=""/>
                  <div class="img2"><img src="assets/images/back/Pattern.png" width="100%" class="radius-img shadow" alt=""/></div>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div>
                    <div className='head-title'>
                      <div className='headTag'><span className='lineRight'>Premium Resort & Rooms</span></div>
                      <h2>Welcome To <span>The Urban Dream!</span></h2>
                      </div>
                    <div>
                      <p>Your Premier Destination Just 2 km from Udaipur Airport.</p>
                      <p>Nestled conveniently near Geetanjali College in Dabok, Udaipur, just 2 km from the airport, The Urban Dream offers the perfect blend of luxury, comfort, and accessibility. Whether you’re traveling for business or leisure, our hotel is designed to provide a memorable stay. Experience exceptional hospitality in a prime location that caters to all your needs!</p>
                      <p>Experience comfort in our 17 super deluxe rooms, designed for relaxation with modern decor and plush bedding. Our 2 spacious family suites are perfect for families, providing ample space to unwind together.</p>
                      <p>Satisfy your culinary cravings at our on-site restaurant, featuring a diverse menu that caters to all tastes. From flavorful vegetarian dishes to mouth-watering non-vegetarian options, our chefs are dedicated to delivering an exceptional dining experience in a warm and inviting atmosphere.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>  
        </>
    );
}

export default About;
