import React from 'react';

const Rooms = () => {
    return (
        <>
        <section className='pageTitle'>
          <div className='pageTitleImg'><img src='assets/images/slider/2.jpg' width="100%" /></div>
          <div className='pageTitle-bgColor'></div>
          <div className='pageTitle-content'>
          <div className="container">
            <div>
              <h1>Rooms</h1>
            </div>
          </div>
          </div>
        </section>

        
           <main>

           {/* <section className='position-relative'>
  <div className='position-absolute top-0 right-0 w-50'><img src='assets/images/back/Interior-scheme.png' className='w-100' /></div>
  <div className="container py-5 position-relative">
              <div className='row'>
                <div className='col-md-6'>
                <div className='imgF'>
                  <div className='img1 imgCover shadow'>  
                  <img src="assets/images/rooms/1.jpg" width="100%" alt=""/>
                  </div>
                  <div class="img2"><img src="assets/images/back/Pattern.png" width="100%" class="radius-img" alt=""/></div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='position-relative'>
                    <div className='head-title'>
                      <div className='headTag'><span className='lineRight'>Rooms</span></div>
                      <h2>Deluxe Room</h2>
                      </div>
                    <div>
                      <p>Hotel is a captivating leisure destination in itself, offering a myriad of services and facilities to experience our truly enriching surroundings. If you are planning a visit to the city of lakes then it is one of the most prominent hotel for your stay.</p>
                    </div>
                    <div>
                        <h4>Room Amenities</h4>
                        <div className='amenitiesList'>
                        <ul className='list-unstyled'>
                        <li>Air Conditioning</li>
                        <li>Bathroom</li>
                        <li>Telephone</li>
                        <li>Closet</li>
                        <li>Chair</li>
                        <li>TV</li>
                        </ul>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section className='position-relative'>
  <div className='position-absolute top-0 right-0 w-40'><img src='assets/images/back/Interior-scheme.png' className='w-100' /></div>
  <div className="container py-5 position-relative">
              <div className='row'>
                <div className='col-md-6'>
                  <div className='imgF'>
                  <div className='img1 imgCover shadow'>  
                  <img src="assets/images/rooms/2.jpg" width="100%" alt=""/>
                  </div>
                  <div class="img2"><img src="assets/images/back/Pattern.png" width="100%" class="radius-img" alt=""/></div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div>
                    <div className='head-title'>
                      <div className='headTag'><span className='lineRight'>Rooms</span></div>
                      <h2>Super Deluxe</h2>
                      </div>
                    <div>
                      <p>Experience unparalleled luxury in our 17 super deluxe rooms at The Urban Dream. Each room is thoughtfully designed with modern decor, plush bedding, and premium amenities, providing a serene retreat for both business and leisure travelers. Your perfect getaway awaits!</p>
                    </div>
                    <div>
                        <h4>Key Features:</h4>
                        <div className='amenitiesList'>
                        <ul className='list-unstyled'>
                        <li>Air Conditioning</li>
                        <li>Telephone</li>
                        <li>Chair</li>
                        <li>Bathroom</li>
                        <li>Closet</li>
                        <li>TV</li>

                        </ul>
                        </div>
                    </div>
                  </div>
                </div>
                </div>

                <div className='row mt-4'>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/rooms/super-deluxe/1.jpg' width="100%"/></div></div>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/rooms/super-deluxe/2.jpg' width="100%"/></div></div>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/rooms/super-deluxe/3.jpg' width="100%"/></div></div>
                </div>

              </div>
          </section>

          <section className='room-back'>
  {/* <div className='position-absolute top-0 right-0 w-50'><img src='assets/images/back/Interior-scheme.png' className='w-100' /></div> */}
  <div className="container py-5">
              <div className='row row-reverse'>
                <div className='col-md-6'>
                <div className='imgF'>
                  <div className='img1 imgCover shadow'> 
                  <img src="assets/images/rooms/3.jpg" width="100%" alt=""/>
                  </div>
                  {/* <div class="img2"><img src="assets/images/back/Pattern.png" width="100%" class="radius-img" alt=""/></div> */}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='position-relative'>
                    <div className='head-title'>
                      <div className='headTag'><span className='lineRight'>Rooms</span></div>
                      <h2>Family Suite</h2>
                      </div>
                    <div>
                      <p>At The Urban Dream, our 2 spacious family suites are designed to make your stay enjoyable and comfortable. Perfect for families, each suite offers ample room for everyone to relax and spend quality time together.</p>
                    </div>
                    <div>
                        <h4>Key Features:</h4>
                        <div className='amenitiesList'>
                        <ul className='list-unstyled'>
                        <li>Air Conditioning</li>
<li>Bathroom</li>
<li>Telephone</li>
<li>Closet</li>
<li>Minibar</li>
<li>TV</li>

                        </ul>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mt-4'>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/rooms/family-suite/1.jpg' width="100%"/></div></div>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/rooms/family-suite/2.jpg' width="100%"/></div></div>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/rooms/family-suite/3.jpg' width="100%"/></div></div>
                </div>

            </div>
          </section>

           </main> 
        </>
    );
}

export default Rooms;
