import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const menu = document.getElementById('listMenu');
        const headerSection = document.getElementById('headerSection');
        if (menu) {
            if (menuOpen) {
                menu.classList.add('open');
                headerSection.classList.add('h-100');
            } else {
                menu.classList.remove('open');
                headerSection.classList.remove('h-100');
            }
        }
    }, [menuOpen]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Function to close the menu when a NavLink is clicked
    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            <section id="headerSection">
                <div className='topHeader'>
                    <div className="container">
                        <div className='ulDiv'>
                            <ul className='list-unstyled'>
                                <li><a href='mailto:info@theurbandream.com'><b>E-mail:</b> info@theurbandream.com</a></li>
                            </ul>
                            <ul className='list-unstyled'>
                                <li><a href='tel:9166115918'><b>Cell:</b> +91-9166115918</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <header>
                    <div className='container-div'>
                        <div className='navHeader'>
                            <div className='logo'>
                                <div className='headerLogo'>
                                    <NavLink to="/" className="link logoLink d-flex" onClick={closeMenu}>
                                        <div className='logoImg'><img src='assets/images/logo/logo.png' /></div>
                                        <div className='logoText'><span>The Urban Dream</span></div>
                                    </NavLink>
                                </div>
                            </div>
                            <div className='navMenu' id="listMenu">
                                <div className='menuItem'>
                                    <ul>
                                        <li><NavLink to="/" className="link" onClick={closeMenu}>Home</NavLink></li>
                                        <li><NavLink to="/about" className="link" onClick={closeMenu}>About</NavLink></li>
                                        <li><NavLink to="/rooms" className="link" onClick={closeMenu}>Rooms</NavLink></li>
                                        <li><NavLink to="/restaurant" className="link" onClick={closeMenu}>Restaurant</NavLink></li>
                                        <li><NavLink to="/gallery" className="link" onClick={closeMenu}>Gallery</NavLink></li>
                                        <li><NavLink to="/contact" className="link" onClick={closeMenu}>Contact</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='btnDiv booking d-sm-none'>
                                <NavLink className='' to="/bookin">
                                    <div className='d-flex'>
                                        {/* <span><img src='assets/svg/chabi.svg' width='40px' /></span> */}
                                        <span className='m-auto'>Booking</span>
                                    </div>
                                </NavLink>
                            </div>
                            <div className='m-auto d-md-none toggle-menu' onClick={toggleMenu}>
                                <img src={menuOpen ? 'assets/svg/close-menu.svg' : 'assets/svg/open-menu.svg'} width="25px" />
                            </div>
                        </div>
                    </div>
                </header>
            </section>
        </>
    );
}

export default Header;
