import React from 'react';

const Restaurant = () => {
    return (
        <>
        <section className='pageTitle'>
          <div className='pageTitleImg'><img src='assets/images/slider/3.jpg' width="100%" /></div>
          <div className='pageTitle-bgColor'></div>
          <div className='pageTitle-content'>
          <div className="container">
            <div>
              <h1>Restaurant</h1>
            </div>
          </div>
          </div>
        </section>

        
        <main>
        <section className='position-relative'>
  <div className='position-absolute top-0 right-0 w-40'><img src='assets/images/back/Interior-scheme.png' className='w-100' /></div>
  <div className="container py-5 position-relative">
              <div className='row'>
                <div className='col-md-6'>
                  <div className='imgF'>
                  <div className='img1 imgCover shadow'>  
                  <img src="assets/images/restaurant/5.jpg" width="100%" alt=""/>
                  </div>         
                  <div class="img2"><img src="assets/images/back/Pattern.png" width="100%" class="radius-img shadow" alt=""/></div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='position-relative'>
                    <div className='head-title'>
                      <div className='headTag'><span className='lineRight'>Veg And Non Veg</span></div>
                      <h2>A Gastronomic Journey Awaits</h2>
                      </div>
                    <div>
                      <p>Welcome to The Urban Dream Restaurant, where your taste buds are in for an exciting journey! Join us for breakfast, lunch, or dinner and explore a diverse menu that highlights the best of vegetarian and non-vegetarian delights.</p>
                      <p>Dine in our inviting ambiance or enjoy the breathtaking views from our rooftop, where every meal becomes a special experience. Savor our expertly crafted dishes, from vibrant salads and hearty curries to flavorful meats and decadent desserts, all prepared with fresh ingredients and a passion for culinary excellence.</p>
                      <p>Come to The Urban Dream Restaurant and discover a world of flavor waiting for you!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className='room-back'>
            <div className="container py-5">
                <div className='row'>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/restaurant/2.jpg' width="100%"/></div></div>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/restaurant/3.jpg' width="100%"/></div></div>
                    <div className='col-md-4'><div className='border-4 shadow'><img src='assets/images/restaurant/4.jpg' width="100%"/></div></div>
                </div>
            </div>
          </section>


        </main>  
        </>
    );
}

export default Restaurant;
