import React, { useState } from 'react';

const Gallery = () => {
    const [lightbox, setLightbox] = useState({
        isOpen: false,
        currentImage: '',
        currentIndex: 0,
    });

    const galleryImages = [
        'assets/images/gallery/lg/1.jpg',
        'assets/images/gallery/lg/2.jpg',
        'assets/images/gallery/lg/3.jpg',
        'assets/images/gallery/lg/4.jpg',
        'assets/images/gallery/lg/5.jpg',
        'assets/images/gallery/lg/6.jpg',
        'assets/images/gallery/lg/7.jpg',
        'assets/images/gallery/lg/8.jpg',
    ];

    const openLightbox = (img, index) => {
        setLightbox({ isOpen: true, currentImage: img, currentIndex: index });
    };

    const closeLightbox = () => {
        setLightbox({ ...lightbox, isOpen: false });
    };

    const showNext = () => {
        const newIndex = (lightbox.currentIndex + 1) % galleryImages.length;
        setLightbox({
            ...lightbox,
            currentImage: galleryImages[newIndex],
            currentIndex: newIndex,
        });
    };

    const showPrev = () => {
        const newIndex = (lightbox.currentIndex - 1 + galleryImages.length) % galleryImages.length;
        setLightbox({
            ...lightbox,
            currentImage: galleryImages[newIndex],
            currentIndex: newIndex,
        });
    };

    return (
        <>
            <section className='pageTitle'>
                <div className='pageTitleImg'>
                    <img src='assets/images/slider/2.jpg' width="100%" alt="Gallery" />
                </div>
                <div className='pageTitle-bgColor'></div>
                <div className='pageTitle-content'>
                    <div className="container">
                        <div>
                            <h1>Gallery</h1>
                        </div>
                    </div>
                </div>
            </section>

            <main>
                <div className="container py-5">
                    <div className='row' id='project-thumb1'>
                        {galleryImages.map((image, index) => (
                            <div key={index} className="item-gallery col-lg-3 col-md-3 mb-4">
                               <div className='galleryImg'>
                                <img
                                    src={image.replace('/lg/', '/sm/')}
                                    alt=""
                                    width="100%"
                                    onClick={() => openLightbox(image, index)}
                                    style={{ cursor: 'pointer' }}
                                />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            {lightbox.isOpen && (
                <div id="lightbox-container" className="lightbox-container">
                    <span className="close" onClick={closeLightbox}>×</span>
                    <span className="prev" onClick={showPrev}>‹</span>
                    <span className="next" onClick={showNext}>›</span>
                    <img className="lightbox-content" id="lightbox-img" src={lightbox.currentImage} alt="Gallery" />
                </div>
            )}
        </>
    );
};

export default Gallery;
