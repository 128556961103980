import React, { useEffect, useState } from 'react';

const Testimonials = () => {
    const testimonials =[
        {
            name: "Debasish Chakraborty",
            content: "Nice hotel, convenient distance from station, good staff, good behavior, nice food, room is excellent, bathroom is clean, good regular service, I stayed with my family, completely satisfied, I referred to all and stay in the hotel, Room 204 and 304 is unique 👍👍👍❤❤❤",
            stars: 5
        },
        {
            name: "Debasish Chakraborty",
            content: "Nice hotel, convenient distance from station, good staff, good behavior, nice food, room is excellent, bathroom is clean, good regular service, I stayed with my family, completely satisfied, I referred to all and stay in the hotel, Room 204 and 304 is unique 👍👍👍❤❤❤",
            stars: 5
        },
    ]

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
    };

    useEffect(() => {
        const timer = setInterval(nextSlide, 5000); // Auto slide every 5 seconds
        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, []);

    return (
        <>
       
       <div className='testimonialsBox'>
       <div className='testimonials'>
        {testimonials.map((testimonial, index)=>
        (
            <div className='testimonialsItem' key={index}  style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
            <div className='user text-center'><h4>{testimonial.name}</h4></div>
            <div className='testimonialsContent'>
              <p className='px-3'>{testimonial.content}</p>
            </div>
            <div className='star'>
              <ul className='d-flex list-unstyled  px-3'>
                {[...Array(testimonial.stars)].map((_, i)=>(
                    <li key={i}><img src='assets/svg/star.svg' /></li>
                ))}
              </ul>
            </div>
          </div>
        ))
        }
       </div>
       <div className='testimonialsBtn'>
         <div className='btnPrev'><button onClick={prevSlide}><img src='assets/svg/right-arrow3.svg' width='40px' height='20px' /></button></div>
         <div className='btnNext'><button onClick={nextSlide}><img src='assets/svg/right-arrow3.svg' width='40px' height='20px' /></button></div>
       </div>
       </div>
       
        </>
    );
}

export default Testimonials;
