import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <>
        <footer>
        <div className="container">
            <div className='row'>
                <div className='col-sm-12 col-md-5 py-2'>
                    <div>
                        <div><h5>Contact Us</h5></div>
                        <div>
                            <div><p><b>Adderss: </b>
                            NH. 76, Airport Road,<br/>
                            Near Geetanjali College Dabok,<br/>
                            Udaipur (Raj.) 313022
                            </p></div>
                        </div>
                        <div className='call-box'>
                            <div><p><b>Call: </b>
                            <a href='tel:02942946374'> +0294-2946374</a><br/>
                            <a href='tel:919265697465'> +91-9265697465</a><br/>
                            <a href='tel:919828024453'> +91-9828024453</a><br/>
                            <a href='tel:919166115918'> +91-9166115918</a>
                            </p></div>
                            {/* <div><p><b>Reception:</b><br/><a href='tel:'>+91 0000000000</a></p></div> */}
                        </div>
                        <div>
                            <div><p><b>Emall: </b><a href='mailto:'>info@theurbandream.com</a></p></div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-2 py-2'>
                    <div>
                        <div><h5>Links</h5></div>
                        <div>
                            <ul className='list-unstyled linkPage'>
                            <li><NavLink className="" to="/about">About</NavLink></li>
                            <li><NavLink className="" to="/rooms">Rooms</NavLink></li>
                            <li><NavLink className="" to="/restaurant">Restaurant</NavLink></li>
                            <li><NavLink className="" to="/gallery">Gallery</NavLink></li>
                            <li><NavLink className="" to="/contact">Contact</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-5 py-2'>
                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3627.01098796675!2d73.8541613753633!3d24.623306178084913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDM3JzIzLjkiTiA3M8KwNTEnMjQuMyJF!5e0!3m2!1sen!2sin!4v1727863763273!5m2!1sen!2sin" width="100%" height="250" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div className='wss'>
        <div class="container">
            <div class="text-center">
                <p class="m-0">Website Designed by <a href="https://www.websearchsolutions.in/">Web Search Solutions</a> | Promoted by <a href="https://digitalmarketingudaipur.com/">digitalmarketingudaipur.com</a></p>
            </div>
        </div>
        </div>
        </footer>  

        <div class="phone-icon">
    <div class="">
    <ul class="list-unstyled m-0">
            <li class=""><a href="tel:9166115918"><img src="assets/svg/call.svg" width="25px"/></a></li>
            <li class=""><a href="https://wa.me/919265697465"><img src="assets/svg/whatsapp.svg" width="25px" alt=""/></a></li>
        </ul>
    </div>
</div>
        </>
    );
}

export default Footer;
