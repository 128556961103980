import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Home from './pages/Home';
import Footer from './components/Footer';
import PhoneBottomTab from './components/PhoneBottomTab';

import About from './pages/About';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import Restaurant from './pages/Restaurant';
import Rooms from './pages/Rooms';

import Bookin from './pages/Bookin';
import Thanks from './pages/Thanks';

const AppContent = () => {
  return (
    <>
    <Header/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/rooms' element={<Rooms/>}/>
      <Route path='/restaurant' element={<Restaurant/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/bookin' element={<Bookin/>}/>
      <Route path='/thanks' element={<Thanks/>}/>
    </Routes>
    <Footer/>
    {/* <PhoneBottomTab/> */}
    </>
  );
}

const App = () => {
  return (
    <Router basename="">
      <AppContent />
    </Router>
  );
};

export default App;



