import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirect
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Bookin = () => {
  const form = useRef();
  const navigate = useNavigate(); // Initialize the navigate hook

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_9g7tude', 'template_uojcqc4', form.current, {
        publicKey: '1w8p1yD-aYBKDFfXE',
      })
      .then(
        () => {
          // Show success toast
          toast.success('Your message has been sent successfully!', {
            position: 'top-center', // Use position as a string
            autoClose: 3000, // Auto close after 3 seconds
          });

          // Delay redirect to allow the toast to be visible
          setTimeout(() => {
            navigate('/thanks'); // Redirect to the /thanks page after 3 seconds
          }, 3000);
        },
        (error) => {
          // Show failure toast
          toast.error('Failed to send the message. Please try again.', {
            position: 'top-center', // Use position as a string
            autoClose: 3000, // Auto close after 3 seconds
          });
        }
      );
  };

    return (
        <>
        <section className='pageTitle'>
          <div className='pageTitleImg'><img src='assets/images/slider/2.jpg' width="100%" /></div>
          <div className='pageTitle-bgColor'></div>
          <div className='pageTitle-content'>
          <div className="container">
            <div>
              <h1>Booking</h1>
            </div>
          </div>
          </div>
        </section>

            <main>
            <ToastContainer />
             <section>
                <div className="container py-5">
                <div className=''>
                    <div className='row justify-content'>
                        {/* <div className='col-sm-12 col-md-6'>

                        </div> */}
                        <div className='col-sm-12 col-md-6'>
                            <div className='bookingFormDiv'>
                            <form ref={form} onSubmit={sendEmail}>
            <div className='row'>
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="" class="form-label">Name</label>
                        <input type="text" class="form-control" name="name" id="" aria-describedby="helpId" placeholder=""/>
                        {/* <small id="helpId" class="form-text text-muted">Help text</small> */}
                    </div>
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="" class="form-label">Phone</label>
                        <input type="text" class="form-control" name="phone" id="" aria-describedby="helpId" placeholder=""/>
                        {/* <small id="helpId" class="form-text text-muted">Help text</small> */}
                    </div>
                    <div class="col-sm-12 col-md-12 mb-3">
                        <label for="" class="form-label">Email</label>
                        <input type="email" class="form-control" name="email" id="" aria-describedby="helpId" placeholder=""/>
                        {/* <small id="helpId" class="form-text text-muted">Help text</small> */}
                    </div>
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="" class="form-label">Check In</label>
                        <input type="date" class="form-control" name="check_in" id="" aria-describedby="helpId" placeholder=""/>
                        {/* <small id="helpId" class="form-text text-muted">Help text</small> */}
                    </div>
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="" class="form-label">Chack Out</label>
                        <input type="date" class="form-control" name="check_out" id="" aria-describedby="helpId" placeholder=""/>
                        {/* <small id="helpId" class="form-text text-muted">Help text</small> */}
                    </div>
                    <div class="col-sm-12 col-md-6 mb-3">
                        <label for="" class="form-label">Rooms</label>
                        <input type="text" class="form-control" name="rooms" id="" aria-describedby="helpId" placeholder=""/>
                        {/* <small id="helpId" class="form-text text-muted">Help text</small> */}
                    </div>
                    <div class="col-sm-12 col-md-6  mb-3">
                        <label for="" class="form-label">Persons</label>
                        <input type="text" class="form-control" name="persons" id="" aria-describedby="helpId" placeholder=""/>
                        {/* <small id="helpId" class="form-text text-muted">Help text</small> */}
                    </div>
                    <div class="col-sm-12 col-md-6  mb-3">
                        <div> <button type='submit' class="btn btn-dark">Submit</button></div>
                    </div>
                    
            </div>
           </form> 
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
             </section>
            </main>
        </>
    );
}

export default Bookin;
