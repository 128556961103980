import { useEffect, useState } from 'react';

function Slider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderImages = [
    {
    id:1,  
    img:"assets/images/slider/1.jpg",
    slideTeg:"Luxury Hotel",
    slideContent:"Welcome To Hotel The Urban Dream",
    },
    {
    id:2,  
    img:"assets/images/slider/2.jpg",
    slideTeg:"Luxury Hotel",
    slideContent:"Welcome To Hotel The Urban Dream",
    },
    {
    id:3,  
    img:"assets/images/slider/3.jpg",
    slideContent:"Welcome To Hotel The Urban Dream",
    },
    {
    id:4,  
    img:"assets/images/slider/4.jpg",
    slideTeg:"Luxury Hotel",
    slideContent:"Welcome To Hotel The Urban Dream",
    },
  ];


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderImages.length); // Update index every 3 seconds
    }, 3000); // 3000ms = 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [sliderImages.length]);

  const goToPrevious = ()=>{
    setCurrentIndex((prevIndex)=>(prevIndex === 0 ? sliderImages.length - 1 : prevIndex -1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderImages.length);
  };

  const goToSlide = (index) =>{
    setCurrentIndex(index);
  }

  return (
    <div className="App">
      <div className='slider-container'>
        <div className='slider-inner' style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {sliderImages.map((image, index)=>(
            <div className='slide-item' key={index}>
              <div className='img-contant'>
              <img src={image.img} className="d-block w-100" alt="..." />
              <div className='slider-bgcolor'></div>
              <div className='slide-contant'>
                <div className='sliderTeg'><span>{image.slideTeg}</span></div>
                <div className='slideHeding'><h1>{image.slideContent}</h1></div>
                <div className='slideBtn'><a>About Us</a></div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <button className='prev-btn' onClick={goToPrevious}><img src='assets/svg/right-arrow.svg' /></button>
          <button className='next-btn' onClick={goToNext}><img src='assets/svg/right-arrow.svg' /></button>
        </div>
        <div className='indicators'>
        {sliderImages.map((_,index)=>(
          <div key={index} className={`indicator ${index===currentIndex ? 'active' : ''}`}></div>
        ))}
        </div>
      </div>
    </div>
  );
}

export default Slider;


