import React from 'react';
import Slider from '../components/Slider';
import Header from '../components/header';
import Testimonials from '../components/Testimonials';
import { NavLink } from 'react-router-dom';

const Home = () => {
    return (
        <>
        <main>
          <Slider/> 

          {/* <section className='sectionForm'>
          <div className="container">
          <div id="home-form">
        <form>
          <div className='formDiv'>
            <div className="mb-3">
              <div><label htmlFor="exampleInputText1" className="form-label">Check In</label></div>
              <div><input type="date" className="form-control" id="exampleInputText1" aria-describedby="textHelp"/></div>
            
            </div>
            <div className="mb-3">
              <div><label htmlFor="exampleInputText1" className="form-label">Check Out</label></div>
              <div><input type="date" className="form-control" id="exampleInputText1" aria-describedby="textHelp"/></div>
             
            </div>
            <div className="mb-3">
              <div><label htmlFor="exampleInputText1" className="form-label">Name</label></div>
              <div><input type="text" className="form-control" id="exampleInputText1" aria-describedby="textHelp"/></div>
             
            </div>
            <div className="mb-3">
              <div><label htmlFor="exampleInputText1" className="form-label">Email</label></div>
              <div><input type="email" className="form-control" name='email' id="exampleInputText1" aria-describedby="textHelp"/></div>
            
            </div>
            <div className='text-center'>
              <button type="button" className="btn btn-light w-100">Submit</button>
            </div>
          </div>
        </form>
        </div>
        </div>
          </section> */}


          <section className='position-relative'>
  <div className='position-absolute top-0 right-0 w-40'><img src='assets/images/back/Interior-scheme.png' className='w-100' /></div>
  <div className="container py-4 position-relative">
              <div className='row'>
                <div className='col-md-5'>
                  <div className='imgAbout'>
                  <img src="assets/images/home/about1.jpg" width="100%" class="up-down radius-img shadow img1 imgCover" alt=""/>
                  <div class="img2"><img src="assets/images/back/Pattern.png" width="100%" class="radius-img shadow" alt=""/></div>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div>
                    <div className='head-title'>
                      <div className='headTag'><span className='lineRight'>Premium Resort & Rooms</span></div>
                      <h2>Welcome to <span>The Urban Dream!</span></h2>
                      </div>
                    <div>
                     <p>Your Premier Destination Just 2 km from Udaipur Airport.</p>
                     <p>Nestled conveniently near Geetanjali College in Dabok, Udaipur, just 2 km from the airport, The Urban Dream offers the perfect blend of luxury, comfort, and accessibility. Whether you’re traveling for business or leisure, our hotel is designed to provide a memorable stay. Experience exceptional hospitality in a prime location that caters to all your needs!</p>
                     <p>Experience comfort in our 17 super deluxe rooms, designed for relaxation with modern decor and plush bedding. Our 2 spacious family suites are perfect for families, providing ample space to unwind together.</p>
                     <p>Satisfy your culinary cravings at our on-site restaurant, featuring a diverse menu that caters to all tastes. From flavorful vegetarian dishes to mouth-watering non-vegetarian options, our chefs are dedicated to delivering an exceptional dining experience in a warm and inviting atmosphere.</p>
                    </div>
                    <div class="mt-2"><NavLink to="/about" className="btn btn-dark">View More</NavLink></div>
                  </div>
                </div>
              </div>
            </div>
          </section>





<section className='airport'>
  <div className="container">
  <div class="head-title text-center">
    <div class="headTag"><span class="lineLeftRight">Caring for our customers</span></div>
    <div><h2>Transfer from Airport to Hotel for Free</h2></div>
  </div>
    <div><p>Just book a free transfer from the airport to our hotel. It offers a chic limousine and a polite professional driver.</p></div>
    <div className='bookingBtn'><a className=''>Booking</a></div>
  </div>
</section>

<section className=''>
  <div className="container py-5">
  <div class="head-title text-center">
    <div class="headTag"><span class="lineLeftRight">Comfort and convenience</span></div>
    <div><h2>Rooms Tariff</h2></div>
  </div>
  <div className='row'>

   
    <div className='col-md-6'>
      <div className='imgCover'>
        <div className='roomImg'><img src="assets/images/rooms/2.jpg" width="100%"/></div>
        <div className='roomContent'>
          <h3>Super Deluxe</h3>
          <p className='roomPDiv'>17 Rooms | 168 sq.ft (16 sq.mt) | Queen Bed</p>
        </div>
        <div className='roomBtn'>
        <NavLink to="/rooms" >View More</NavLink>
          </div>
        <div className='roomIcon'>
          <ul className='d-flex list-unstyled'>
          <li><img src='assets/svg/food.svg' width='20px' /></li>
            <li><img src='assets/svg/tv.svg' width='20px' /></li>
            <li><img src='assets/svg/wifi.svg' width='20px' /></li>
            <li><img src='assets/svg/ac.svg' width='20px' /></li>
          </ul>
        </div>
      </div>
    </div>

    <div className='col-md-6'>
      <div className='imgCover'>
        <div className='roomImg'><img src="assets/images/rooms/3.jpg" width="100%"/></div>
        <div className='roomContent'>
          <h3>Family Suite</h3>
          <p className='roomPDiv'>2 Rooms | 180 sq.ft (17 sq.mt)</p>
        </div>
        <div className='roomBtn'>
        <NavLink to="/rooms" >View More</NavLink>
          </div>
        <div className='roomIcon'>
          <ul className='d-flex list-unstyled'>
          <li><img src='assets/svg/food.svg' width='20px' /></li>
            <li><img src='assets/svg/tv.svg' width='20px' /></li>
            <li><img src='assets/svg/wifi.svg' width='20px' /></li>
            <li><img src='assets/svg/ac.svg' width='20px' /></li>
          </ul>
        </div>
      </div>
    </div>

  </div>
  </div>
</section>

<section>
  <div className="container-fluid">
    <div className='row m-0'>
          <div className='col-sm-12 col-md-3 p-0'>
           <div className='serviceBox'>
            <div className='imgBox'>
              <img src='assets/images/restaurant/restaurant.jpg' width="100%"/>
              <div className='servicesName'><h3>Restaurant</h3></div>
            </div>
            <div className='serviceContent'>
                {/* <div className='serviceBtn'><a><img src='assets/svg/right-arrow2.svg' width='20px' /></a></div> */}
                <div className='serviceIconName'>
                  <div className='serviceIcon'><img src='assets/svg/restaurant.svg' width="30px" /></div>
                  <div className='serviceName'><h4>Restaurant</h4></div>
                </div>
              </div>
           </div>
            </div>

            <div className='col-sm-12 col-md-3 p-0'>
           <div className='serviceBox'>
            <div className='imgBox'>
              <img src='assets/images/services/parking.jpg' width="100%"/>
              <div className='servicesName'><h3>Parking</h3></div>
            </div>
            <div className='serviceContent'>
                {/* <div className='serviceBtn'><a><img src='assets/svg/right-arrow2.svg' width='20px' /></a></div> */}
                <div className='serviceIconName'>
                  <div className='serviceIcon'><img src='assets/svg/parking.svg' width="30px" /></div>
                  <div className='serviceName'><h4>Parking</h4></div>
                </div>
              </div>
           </div>
            </div>

            

<div className='col-sm-12 col-md-3 p-0'>
           <div className='serviceBox'>
            <div className='imgBox'>
              <img src='assets/images/services/power-backup.jpg' width="100%"/>
              <div className='servicesName'><h3>Power Backup</h3></div>
            </div>
            <div className='serviceContent'>
                {/* <div className='serviceBtn'><a><img src='assets/svg/right-arrow2.svg' width='20px' /></a></div> */}
                <div className='serviceIconName'>
                  <div className='serviceIcon'><img src='assets/svg/power.svg' width="30px" /></div>
                  <div className='serviceName'><h4>Power Backup</h4></div>
                </div>
              </div>
           </div>
            </div>

            <div className='col-sm-12 col-md-3 p-0'>
           <div className='serviceBox'>
            <div className='imgBox'>
              <img src='assets/images/services/travel-desk.jpg' width="100%"/>
              <div className='servicesName'><h3>Travel Desk</h3></div>
            </div>
            <div className='serviceContent'>
                {/* <div className='serviceBtn'><a><img src='assets/svg/right-arrow2.svg' width='20px' /></a></div> */}
                <div className='serviceIconName'>
                  <div className='serviceIcon'><img src='assets/svg/travel-desk.svg' width="30px" /></div>
                  <div className='serviceName'><h4>Travel Desk</h4></div>
                </div>
              </div>
           </div>
            </div>


    </div>
  </div>
</section>


<section className='position-relative'>
  <div className='position-absolute top-0'><img src='assets/images/back/Interior-scheme-2.png' className='w-100' /></div>
  <div className="container py-5">
    <div className='head-title text-center'>
      <div className='headTag'><span className='lineLeftRight'>Testimonials and opinions</span></div>
      <div><h2>What our customers say</h2></div>
    </div>
    <div className='row'>
      <div className='col-md-6 px-sm-0'><div className='imgCover'><img src='assets/images/home/hall.jpg' width="100%"/></div></div>
      <div className='col-md-6 m-auto px-sm-0'>
       
     <Testimonials/>

      </div>
    </div>
  </div>
</section>


        </main>
        </>
    );
}

export default Home;
